import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';
import { SharedModule } from '../shared';
import { AuthComponent } from './auth.component';
import { USERS } from './data';
import { interceptors } from './interceptors';
import { services } from './services';
import { states } from './store';

@NgModule({
  imports: [SharedModule, NgxsModule.forFeature([...states])],
  exports: [],
  providers: [
    ...services,
    ...interceptors,
    {
      provide: USERS,
      useValue: environment.users,
    },
  ],
  declarations: [AuthComponent],
})
export class AuthModule {}
