import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPlan } from '@app/data';

@Component({
  selector: 'pip-deviations-acknowledgement',
  templateUrl: './deviations-acknowledgement.component.html',
  styleUrls: ['./deviations-acknowledgement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviationsAcknowledgementComponent implements OnInit {
  @Input() public plan: IPlan;
  @Output() private confirmDeviations = new EventEmitter<IPlan>();

  public form: FormGroup;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const payload: IPlan = {
      id: this.plan.id,
      type: this.plan.type,
      attributes: {
        deviations_confirmation: true,
      },
    };

    this.confirmDeviations.emit(payload);
  }

  private initForm(): void {
    this.form = new FormGroup({
      acknowledgement: new FormControl(this.plan.attributes.deviations_confirmation, [Validators.requiredTrue]),
    });

    if (this.plan.attributes.deviations_confirmation) {
      this.form.disable();
    }
  }
}
