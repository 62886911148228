import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEmployee } from '@app/data';

@Component({
  selector: 'pip-off-work-employee-list',
  templateUrl: './off-work-employee-list.component.html',
  styleUrls: ['./off-work-employee-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffWorkEmployeeListComponent implements OnInit {
  @Input() public employees: IEmployee[];
  @Output() public removeClaim = new EventEmitter<string>();

  public get offwork(): IEmployee[] {
    return this.employees && this.employees.filter(e => e.attributes.absent);
  }

  constructor() {}

  public ngOnInit(): void {}
}
