import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { IFieldStatus, IPlan, PlanStatus, Role } from '@app/data';
import { NbDialogService } from '@nebular/theme';

import { IPlanMenuItem } from '../plan-menu-item/plan-menu-item.component';
import { PlanMenu } from './plan.menu';

@Component({
  selector: 'pip-plan-menu',
  templateUrl: './plan-menu.component.html',
  styleUrls: ['./plan-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanMenuComponent implements OnChanges {
  @Input() plan: IPlan;
  @Input() roles: Role[];

  public menuItems: IPlanMenuItem[];

  constructor(private dialog: NbDialogService) {}

  public ngOnChanges(): void {
    if (!this.plan) return;

    this.menuItems = PlanMenu(this.plan, this.plan.attributes.status as PlanStatus, this.roles).filter(
      (i) => !i.hidden,
    );
  }

  public onInfo(status: IFieldStatus, dialog: TemplateRef<any>): void {
    this.dialog.open(dialog, { context: status });
  }
}
