<fieldset [formGroup]="controlContainer.control">
  <div class="form-row">
    <div class="form-group col">
      <label for="first_name" class="label">First name</label>
      <input type="text" nbInput fullWidth id="first_name" formControlName="first_name" />
    </div>
    <div class="form-group col">
      <label for="middle_name" class="label">Middle name</label>
      <input type="text" nbInput fullWidth id="middle_name" formControlName="middle_name" />
    </div>
    <div class="form-group col">
      <label for="last_name" class="label">Last name</label>
      <input type="text" nbInput fullWidth id="last_name" formControlName="last_name" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="born_at" class="label">Date of birth</label>
      <input
        type="text"
        nbInput
        fullWidth
        id="born_at"
        formControlName="born_at"
        readonly
        [nbDatepicker]="dobDatepicker"
      />
      <nb-datepicker format="yyyy-MM-dd" #dobDatepicker></nb-datepicker>
    </div>
    <div class="form-group col">
      <label for="relationship" class="label">Relationship</label>
      <nb-select fullWidth id="relationship" formControlName="relationship">
        <nb-option value="Spouse">Spouse</nb-option>
        <nb-option value="Child">Child</nb-option>
        <nb-option value="CommonLaw">Common-law</nb-option>
      </nb-select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label [for]="'gender_' + controlContainer.name" class="label">Gender</label>
      <nb-radio-group [name]="'gender_' + controlContainer.name" formControlName="gender" class="d-flex">
        <nb-radio [disabled]="controlContainer.disabled" value="Male">Male</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" value="Female">Female</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" value="Other">Other</nb-radio>
      </nb-radio-group>
    </div>
    <div class="form-group col">
      <label [for]="'student_' + controlContainer.name" class="label">Student</label>
      <nb-radio-group [name]="'student_' + controlContainer.name" formControlName="student" class="d-flex">
        <nb-radio [disabled]="controlContainer.disabled" [value]="false">No</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" [value]="true">Yes</nb-radio>
      </nb-radio-group>
    </div>
    <div class="form-group col">
      <label [for]="'disabled_' + controlContainer.name" class="label">Disabled</label>
      <nb-radio-group [name]="'disabled_' + controlContainer.name" formControlName="disabled" class="d-flex">
        <nb-radio [disabled]="controlContainer.disabled" [value]="false">No</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" [value]="true">Yes</nb-radio>
      </nb-radio-group>
    </div>
  </div>
</fieldset>
