<ng-container *ngIf="renewal$ | async as renewal">
  <ng-container *ngIf="(userRoles$ | async) && (isActiveUserAnAdvisorOrAnalyst$ | async); else LimitedView">
    <ng-container *ngIf="renewal.attributes.status === renewalStatus.Ready">
      <nb-alert status="warning">This Renewal has been approved and will take effect on the Renewal Date.</nb-alert>
    </ng-container>
    <nb-accordion>
      <nb-accordion-item
        *ngFor="let segment of segments$ | async as segments; let idx = index"
        [expanded]="idx === segments.length - 1"
      >
        <nb-accordion-item-header>
          <ng-container>{{ segment?.attributes.type | segmentType }}</ng-container>
          <ng-container *ngIf="segment?.attributes.type === segmentType.Revision">
            {{ getRevisionIndex(segments, segment?.id) + 1 }}
          </ng-container>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-renewal-segment
            [renewal]="renewal$ | async"
            [user]="user$ | async"
            [roles]="userRoles$ | async"
            [segment]="segment"
            [media]="segmentMediaByIndex$(idx) | async"
            (save)="handleSaveSegment($event)"
            (update)="handleUpdateSegment($event, warningDialog)"
            (sign)="handleSignRenewal($event)"
          ></pip-renewal-segment>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

    <ng-container *ngIf="(renewalRates$ | async)?.length">
      <div class="mt-3">
        <h2>Renewal Rates</h2>
        <nb-card>
          <nb-card-body>
            <pip-common-rate-properties [resource]="renewal" [firstRenewal]="false"></pip-common-rate-properties>
          </nb-card-body>
        </nb-card>
        <nb-accordion>
          <nb-accordion-item *ngFor="let rate of renewalRates$ | async">
            <nb-accordion-item-header>{{ rate.attributes.name }}</nb-accordion-item-header>
            <nb-accordion-item-body>
              <pip-rate-properties [plan]="renewal" [rate]="rate"></pip-rate-properties>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <div class="mt-3" *ngIf="showApproveRenewalBtn$ | async">
          <button nbButton status="primary" (click)="handleApproveRenewal(renewal, warningDialog)">
            Approve renewal
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #warningDialog let-data let-ref="dialogRef">
      <nb-card status="basic">
        <nb-card-body>
          <p>Are you sure you want to {{ data.actionLabel }}?</p>
          <p>This action is <strong>not</strong> reversible.</p>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-between">
          <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
          <button nbButton status="primary" (click)="ref.close(true)">Confirm</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #LimitedView>
  <div *ngFor="let segment of segments$ | async as segments; let idx = index">
    <h3 class="h6">{{ segment?.attributes.type | segmentType }}</h3>
    <pip-renewal-segment
      [limited]="true"
      [renewal]="renewal$ | async"
      [user]="user$ | async"
      [roles]="userRoles$ | async"
      [segment]="segment"
      [media]="segmentMediaByIndex$(idx) | async"
    ></pip-renewal-segment>
  </div>
</ng-template>
