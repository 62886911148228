<div class="d-flex">
  <button
    [ngClass]="{ invis: !menuItem.field_status }"
    nbButton
    status="primary"
    ghost
    size="tiny"
    class="px-1"
    [disabled]="!menuItem.field_status"
    (click)="info.emit(menuItem.field_status)"
  >
    <nb-icon
      style="vertical-align: middle; font-size: 0.5em"
      icon="radio-button-on"
      [status]="menuItem?.field_status?.complete ? 'success' : 'warning'"
    ></nb-icon>
  </button>
  <a
    nbButton
    status="basic"
    fullWidth
    ghost
    class="d-flex justify-content-start px-1"
    [routerLink]="['./', menuItem.link]"
    routerLinkActive="status-primary"
    >{{ menuItem.label }}</a
  >
</div>
