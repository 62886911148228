import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule } from '@nebular/theme';

import { AppComponent } from './app.component';
import { AppRoutergModule } from './app.router.module';
import { AuthModule } from './auth';
import { PlansModule } from './core/plans/plans.module';
import { DataModule } from './data';
import { SharedModule } from './shared';
import { StoreModule } from './store';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutergModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule,
    DataModule,
    AuthModule,
    PlansModule,
    NbThemeModule.forRoot({ name: 'pluginplay' }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
