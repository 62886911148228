import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICarrier, IUser } from '@app/data';

@Component({
  selector: 'pip-carrier-invite',
  templateUrl: './carrier-invite.component.html',
  styleUrls: ['./carrier-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierInviteComponent implements OnInit {
  @Input() public carrier: ICarrier;
  @Input() public users: EntityMap<IUser>;
  @Output() public invite = new EventEmitter<{ id: string; contacts: { name: string; id: string }[] }>();

  public get carrierEmailHref(): string {
    return `mailto:${this.users[this.carrier.relationships.users.data[0].id]?.attributes?.email}`;
  }

  public get contacts(): { name: string; id: string }[] {
    return this.carrier.relationships.users.data.map((user) => {
      const found = this.users[user.id];

      return { id: user.id, name: `${found.attributes.first_name} ${found.attributes.last_name}` };
    });
  }

  constructor() {}

  public ngOnInit(): void {}
}
