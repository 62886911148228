import { Injectable } from '@angular/core';
import { DataType, Role } from '@app/data/enums';
import * as Data from '@app/data/models';
import { IncludeUsers } from '@app/data/store/users/users.action'; // @TODO: Fix circular dependency
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IncludeProfiles, IncludeRoles } from '@app/data';
import { AuthService } from '../../services';
import { GetAuthUser, Login, Logout, ResetPassword, VerifyCode, VerifyEmail, VerifyInvitation } from './auth.actions';
import { environment } from '@env/environment';

export interface AuthStateModel {
  token: Data.IToken;
  user: Data.IUser;
}

export const authStateDefaults: AuthStateModel = {
  token: null,
  user: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: authStateDefaults,
})
@Injectable()
export class AuthState {
  constructor(private auth: AuthService, private toastr: NbToastrService) {}

  //#region Selectors
  @Selector()
  public static token(state: AuthStateModel): string {
    return state.token.attributes.token;
  }

  @Selector()
  public static isLoggedIn(state: AuthStateModel): boolean {
    return !!state.token.attributes.token;
  }

  @Selector()
  public static roles(state: AuthStateModel): Role[] {
    return state.user.relationships.roles.data.map((role) => role.id as Role);
  }

  @Selector()
  public static user(state: AuthStateModel): Data.IUser {
    return state.user;
  }

  @Selector()
  public static userProfileId(state: AuthStateModel): string {
    return state.user.relationships.profiles.data.id;
  }
  //#endregion

  //#region Actions

  @Action(GetAuthUser)
  public getAuthUser(ctx: StateContext<AuthStateModel>, action: GetAuthUser) {
    return this.auth.getAuthUser().pipe(
      tap((res) => {
        const user = res.data;

        ctx.patchState({
          user,
        });

        const roles = res.included.filter((i) => i && i.type === DataType.Roles) as Data.IRole[];
        const profiles = res.included.filter((i) => i && i.type === DataType.Profiles) as Data.IProfile[];

        ctx.dispatch(new IncludeUsers([user]));

        if (roles) ctx.dispatch(new IncludeRoles(roles));
        if (profiles) ctx.dispatch(new IncludeProfiles(profiles));
      }),
    );
  }

  @Action(Login)
  public login(ctx: StateContext<AuthStateModel>, action: Login): Observable<Data.Response<Data.IToken>> {
    const { email, password } = action.payload;

    const payload: Partial<Data.IUser> = {
      type: DataType.Users,
      attributes: { email, password },
    };

    return this.auth.login(payload).pipe(
      tap((res) => {
        const token = res.data;
        const user: Data.IUser = res.included
          .filter((obj) => obj.type === DataType.Users)
          .find((obj) => obj.id === res.data.relationships.users.data.id) as Data.IUser;

        ctx.patchState({ token, user });

        const roles = res.included.filter((i) => i && i.type === DataType.Roles) as Data.IRole[];
        const profiles = res.included.filter((i) => i && i.type === DataType.Profiles) as Data.IProfile[];

        ctx.dispatch(new IncludeUsers([user]));

        if (roles) ctx.dispatch(new IncludeRoles(roles));
        if (profiles) ctx.dispatch(new IncludeProfiles(profiles));
      }),
    );
  }

  @Action(VerifyInvitation)
  public verifyInvitation(
    ctx: StateContext<AuthStateModel>,
    action: VerifyInvitation,
  ): Observable<Data.Response<Data.IToken>> {
    const { url } = action.payload;

    return this.auth.verifyInvitation(url).pipe(
      tap((res) => {
        const token = res.data;
        const user: Data.IUser = res.included
          .filter((obj) => obj.type === DataType.Users)
          .find((obj) => obj.id === res.data.relationships.users.data.id) as Data.IUser;

        ctx.patchState({ token, user });
        ctx.dispatch(new IncludeUsers([user]));
      }),
    );
  }

  @Action(Logout)
  public logout(ctx: StateContext<AuthStateModel>): void {
    ctx.dispatch(new StateResetAll());
    ctx.dispatch(new StateReset(AuthState));
    window.location.href = `${environment.url.api}/logout`;
  }

  @Action(ResetPassword)
  public resetPassword(ctx: StateContext<AuthStateModel>, action: ResetPassword): Observable<Data.Response<any>> {
    const { email } = action.payload;

    const user: Partial<Data.IUser> = {
      id: email,
      type: DataType.Users,
      attributes: { email },
    };

    return this.auth
      .resetPassword(user)
      .pipe(
        tap(() =>
          this.toastr.show('Please check your email for further instructions.', 'Password reset request sent.'),
        ),
      );
  }

  @Action(VerifyEmail)
  public verifyEmail(ctx: StateContext<AuthStateModel>, action: VerifyEmail): Observable<void> {
    const { email } = action.payload;

    const user: Partial<Data.IUser> = {
      id: email,
      type: DataType.Users,
      attributes: { email },
    };

    return this.auth
      .verifyEmail(user)
      .pipe(
        tap(() =>
          this.toastr.show('Please check your email for further instructions.', 'Verification code has been sent.'),
        ),
      );
  }

  @Action(VerifyCode)
  public verifyCode(ctx: StateContext<AuthStateModel>, action: VerifyCode): Observable<Data.Response<Data.IToken>> {
    const { email, code } = action.payload;

    const payload: Partial<Data.IUser> = {
      type: DataType.Users,
      attributes: { email, token: code },
    };

    return this.auth.verifyCode(payload).pipe(
      tap((res) => {
        const token = res.data;
        const user: Data.IUser = res.included
          .filter((obj) => obj.type === DataType.Users)
          .find((obj) => obj.id === res.data.relationships.users.data.id) as Data.IUser;

        ctx.patchState({ token, user });
      }),
    );
  }

  //#endregion
}
