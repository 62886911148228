<table class="table table-bordered table-striped" *ngIf="offwork.length > 0">
  <thead>
    <tr>
      <th>Employee</th>
      <th>Type</th>
      <th>Cause</th>
      <th>Began</th>
      <th>Expected return</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let employee of offwork">
      <td>{{ employee.attributes?.last_name }}, {{ employee.attributes.first_name }}</td>
      <td>{{ employee.attributes?.claims?.type }}</td>
      <td>{{ employee.attributes?.claims?.cause }}</td>
      <td>{{ employee.attributes?.claims?.start_at | date: 'shortDate' }}</td>
      <td>{{ employee.attributes?.claims?.end_at | date: 'shortDate' }}</td>
      <td>
        <button type="button" nbButton ghost status="danger" size="tiny" fullWidth (click)="removeClaim.emit(employee.id)">
          <nb-icon icon="trash-2-outline"></nb-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>
