import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { ClaimTypeDictionary, IEmployee, IPlan, IRate } from '@app/data';
import {
  NbDialogService,
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from '@nebular/theme';

@Component({
  selector: 'pip-employees-table',
  templateUrl: './employees-table.component.html',
  styleUrls: ['./employees-table.component.scss'],
})
export class EmployeesTableComponent implements OnChanges {
  @Input() public showEnroll: boolean;
  @Input() public showEmployeeId: boolean;
  @Input() public employees: IEmployee[];
  @Input() public rates: IRate[];
  @Output() public delete = new EventEmitter<{ id: string; first_name: string; last_name: string }>();

  public dataSource: NbTreeGridDataSource<IEmployee>;
  public columns = ['name', 'rate', 'email', 'id'];

  public sortDirection: NbSortDirection = NbSortDirection.ASCENDING;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<IEmployee>, private dialog: NbDialogService) {}

  public ngOnChanges(): void {
    if (!this.employees) {
      return;
    }

    this.dataSource = this.dataSourceBuilder.create(
      this.employees.map((p) => {
        return {
          data: {
            id: p.id,
            first_name: p.attributes.first_name,
            last_name: p.attributes.last_name,
            status: p.attributes.status,
            claims: p.attributes.claims && p.attributes.claims.type && ClaimTypeDictionary[p.attributes.claims.type],
            field_statuses: (p.meta && p.meta.field_statuses) || {},
            email: p.attributes.email,
            last_invited_at: p.attributes.last_invited_at,
            signed_at: p.attributes.signed_at,
            rate: p.relationships && p.relationships.rates && p.relationships.rates.data['id'],
            attributes: p.attributes,
            complete: p.attributes.complete,
            hasMedia: p.relationships?.media?.data?.length,
          },
        };
      }),
    );
  }

  public changeSort(sortRequest: NbSortRequest): void {
    this.dataSource.sort(sortRequest);
    // this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  // public getDirection(column: string): NbSortDirection {
  //   if (column === this.sortColumn) {
  //     return this.sortDirection;
  //   }
  //   return NbSortDirection.NONE;
  // }

  public showEmployeeInfoDialog(employee, dialogTemplate: TemplateRef<any>): void {
    const fs = employee.field_statuses && Object.values(employee.field_statuses);
    this.dialog.open(dialogTemplate, { context: { employee, fs } });
  }

  public getRateName(rateId: string): string {
    const rate = this.rates && this.rates.find((r) => r.id === rateId);
    return rate && rate.attributes.name;
  }
}
