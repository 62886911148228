<div *ngIf="carrier">
  <h2 class="h4">{{ carrier.attributes.name }}</h2>

  <div class="row">
    <div class="col">
      <button nbButton status="primary" size="small" (click)="invite.emit({ id: carrier.id, contacts: contacts })">
        Invite
      </button>
    </div>
    <div class="col">
      <a
        nbButton
        status="primary"
        size="small"
        ghost
        class="d-flex justify-content-start"
        fullWidth
        [href]="carrierEmailHref"
      >
        <nb-icon icon="email-outline"></nb-icon>
        Contact {{ carrier.attributes.name }} representative
      </a>
    </div>
  </div>
</div>
<hr />
