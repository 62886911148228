<table [nbTreeGrid]="dataSource">
  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="columns"></tr>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: columns"></tr>

  <!-- ID/Actions -->
  <ng-container nbTreeGridColumnDef="id">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef><span class="sr-only">Actions</span></th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <pip-attach-employee-id *ngIf="showEmployeeId && row.data" [employee]="row.data"></pip-attach-employee-id>
      <pip-invite-employee
        *ngIf="showEnroll"
        #invEmployee
        [employee]="row.data"
        [fieldStatuses]="row.data.field_statuses"
      ></pip-invite-employee>
      <a nbButton status="primary" size="tiny" ghost *ngIf="row.data" [routerLink]="['./', row.data.id]">
        <nb-icon icon="edit-outline"></nb-icon>
        Edit
      </a>
      <button nbButton size="tiny" ghost status="danger" (click)="delete.emit(row.data)">
        <nb-icon icon="trash-2"></nb-icon>
        Delete
      </button>
    </td>
  </ng-container>

  <!-- First name -->
  <ng-container nbTreeGridColumnDef="name">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Name</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <button
        nbButton
        status="primary"
        ghost
        size="tiny"
        class="mr-3"
        (click)="showEmployeeInfoDialog(row.data, employeeInfoDialog)"
      >
        <nb-icon
          style="vertical-align: middle; font-size: 0.5em"
          icon="radio-button-on"
          [status]="row.data.field_statuses | fieldStatus"
        ></nb-icon>
      </button>
      <a [routerLink]="['./', row.data.id]" style="vertical-align: middle"
        >{{ row.data.last_name }}, {{ row.data.first_name }}</a
      >
      <nb-icon
        *ngIf="row.data.hasMedia"
        style="vertical-align: middle; font-size: 0.85em; opacity: 0.5"
        icon="attach"
      ></nb-icon>
      <span
        class="ml-1 bg-warning p-1 rounded"
        style="vertical-align: middle; font-size: 0.65em"
        *ngIf="row.data.claims"
        >{{ row.data.claims }}</span
      >
    </td>
  </ng-container>

  <!-- Email -->
  <ng-container nbTreeGridColumnDef="email">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Email</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row" [ngClass]="{ 'bg-warning': !row.data.email }">
      {{ row.data.email }}
    </td>
  </ng-container>

  <!-- Rate -->
  <ng-container nbTreeGridColumnDef="rate">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Class</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{ getRateName(row.data.rate) }}</td>
  </ng-container>
</table>

<ng-template #employeeInfoDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Employee info</nb-card-header>
    <nb-card-body>
      <pip-field-status-alert *ngFor="let item of data.fs" [fieldStatus]="item"></pip-field-status-alert>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
