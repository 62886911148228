import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFieldStatus } from '@app/data';

export interface IPlanMenuItem {
  label: string;
  field_status: IFieldStatus;
  link: string | string[];
  hidden: boolean;
}

@Component({
  selector: 'pip-plan-menu-item',
  templateUrl: './plan-menu-item.component.html',
  styleUrls: ['./plan-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanMenuItemComponent implements OnInit {
  @Input() public menuItem: IPlanMenuItem;
  @Output() public info = new EventEmitter<IFieldStatus>();

  constructor() {}

  public ngOnInit(): void {}
}
