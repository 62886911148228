import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CanadianProvinces, ICarrier, IPlan, IProfile } from '@app/data';
import { escapeRegExp } from '@app/utils/escape-reg-exp';
import { NbDialogService } from '@nebular/theme';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'pip-master-authorization',
  templateUrl: './master-authorization.component.html',
  styleUrls: ['./master-authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterAuthorizationComponent implements OnInit {
  @Input() public profile: IProfile;
  @Input() public carrier: ICarrier;
  @Input() public plan: IPlan;

  @Output() public save = new EventEmitter<IPlan>();

  public provinces = CanadianProvinces;
  public form: FormGroup;

  public get signatureName(): string {
    return this.profile && `${this.profile.attributes.first_name} ${this.profile.attributes.last_name}`;
  }

  constructor(public dialog: NbDialogService) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(templateRef: TemplateRef<any>): void {
    const dialog = this.dialog.open(templateRef);

    dialog.onClose
      .pipe(
        take(1),
        filter((is) => is),
      )
      .subscribe(() => {
        const { id, type } = this.plan;

        const payload: IPlan = {
          id,
          type,
          attributes: { ...this.form.value },
        };

        this.save.emit(payload);
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      pa_signature: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(`^${escapeRegExp(this.signatureName)}$`, 'i')),
      ]),
      pa_signature_province: new FormControl(null, [Validators.required]),
    });
  }
}
