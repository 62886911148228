import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import * as Data from '@app/data/models';
import { ApiService } from '@app/data/services';

import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private api: ApiService) {}

  public getAuthUser(): Observable<Data.Response<Data.IUser>> {
    const params: Params = { include: 'roles,organizations,profiles,plans' };

    return this.api.get(['user'], { params });
  }

  public login(data: Partial<Data.IUser>): Observable<Data.Response<Data.IToken>> {
    const params: Params = { include: 'users' };
    return this.api.post(['tokens'], data, { params });
  }

  public resetPassword(data: Partial<Data.IUser>): Observable<Data.Response<any>> {
    return this.api.patch(['passwords'], data);
  }

  public verifyEmail(data: Partial<Data.IUser>): Observable<any> {
    return this.api.post(['users'], data);
  }

  public verifyCode(data: Partial<Data.IUser>): Observable<Data.Response<Data.IToken>> {
    const params: Params = { include: 'users' };
    return this.api.post(['tokens'], data, { params });
  }

  public verifyInvitation(url: string): Observable<Data.Response<Data.IToken>> {
    return this.api.get(url);
  }
}
