<fieldset [formGroup]="controlContainer.control">
  <div class="form-row">
    <ng-container *ngIf="maritalStatus; else Warning">
      <div class="form-group col">
        <label for="coverage_status_health" class="label">Health</label>
        <nb-radio-group class="d-flex" fullWidth name="coverage_status_health" formControlName="coverage_status_health">
          <nb-radio [disabled]="disabled" [value]="null">N/A</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showSingleOpt" [value]="coverageStatus.Single">Single</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showSingleParentOpt" [value]="coverageStatus.SingleParent"
            >Single Parent
          </nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showCoupleOpt" [value]="coverageStatus.Couple">Couple</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showFamilyOpt" [value]="coverageStatus.Family">Family</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showWaivedOpt" [value]="coverageStatus.Waived">Waived</nb-radio>
        </nb-radio-group>
      </div>
      <div class="form-group col">
        <label for="coverage_status_dental" class="label">Dental</label>
        <nb-radio-group class="d-flex" fullWidth name="coverage_status_dental" formControlName="coverage_status_dental">
          <nb-radio [disabled]="disabled" [value]="null">N/A</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showSingleOpt" [value]="coverageStatus.Single">Single</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showSingleParentOpt" [value]="coverageStatus.SingleParent"
            >Single Parent
          </nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showCoupleOpt" [value]="coverageStatus.Couple">Couple</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showFamilyOpt" [value]="coverageStatus.Family">Family</nb-radio>
          <nb-radio [disabled]="disabled" *ngIf="showWaivedOpt" [value]="coverageStatus.Waived">Waived</nb-radio>
        </nb-radio-group>
      </div>
    </ng-container>
  </div>
  <div style="max-width: 65ch">
    <p>
      <strong>Important Note</strong> - if you have selected to waive Health and/or Dental benefits for yourself and/or
      your dependants, and your spouse loses coverage through their current employment, notify your Plan Administrator
      immediately; otherwise, the incumbent carrier may require you to provide proof of medical insurability, limit or
      restrict benefits, or fully decline coverage.
    </p>
    <p>
      If you have waived Health and/or Dental, or wish to coordinate coverage with your spouse's plan, please provide:
    </p>
  </div>
  <div class="form-row" *ngIf="showPolicyInputs">
    <div class="form-group col">
      <label for="spouse_carrier" class="label">Spouse Carrier</label>
      <input type="text" nbInput fullWidth id="spouse_carrier" formControlName="spouse_carrier" />
    </div>
    <div class="form-group col">
      <label for="spouse_policy_number" class="label">Spouse Policy Number</label>
      <input type="text" nbInput fullWidth id="spouse_policy_number" formControlName="spouse_policy_number" />
    </div>
  </div>
  <div class="form-row" *ngIf="showRAMQ">
    <div class="form-group col">
      <nb-alert accent="primary">
        <h2 class="h4">Quebec residents <small class="caption">age 65 or over only</small></h2>
        <label for="ramq" class="label">Are you participating in the RAMQ drug plan?</label>
        <nb-radio-group class="d-flex" fullWidth name="ramq" formControlName="ramq">
          <nb-radio [disabled]="disabled" [value]="false">No</nb-radio>
          <nb-radio [disabled]="disabled" [value]="true">Yes</nb-radio>
        </nb-radio-group>
      </nb-alert>
    </div>
  </div>
</fieldset>

<ng-template #Warning>
  <nb-alert accent="danger"> You need to select a marital status before entering coverage information. </nb-alert>
</ng-template>
