<div *ngIf="quote && carrier">
  <div class="row">
    <div class="col">
      <h2 class="h4">
        {{ carrier.attributes.name }}
      </h2>

      <p *ngIf="quote.attributes.created_at">Invited {{ quote.attributes.created_at | date }}</p>
      <p *ngIf="quote.attributes.response && quote.attributes.updated_at">Response submitted {{ quote.attributes.updated_at | date }}</p>
      <p *ngIf="quote.attributes.notes">{{ quote.attributes.notes }}</p>
    </div>
    <div class="col">
      <nb-alert class="d-block" *ngIf="quote.attributes.selected && quote.attributes.selected_media_id && selectedMedia" accent="success">
        <dl class="m-0">
          <div class="d-flex justify-content-between">
            <dt>
              Selected:
            </dt>
            <dd>
              {{ selectedMedia.attributes.name }}
            </dd>
          </div>
          <div class="d-flex justify-content-between">
            <dt>
              Plan Start Date:
            </dt>
            <dd>
              {{ plan.attributes.start_at | date }}
            </dd>
          </div>
        </dl>
      </nb-alert>

      <nb-alert *ngIf="quote.attributes.status === 'Declined'" accent="danger">
        {{ quote.attributes.status }}
      </nb-alert>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div *ngFor="let m of media">
        <a [href]="m.links.self">
          <span>{{ m.attributes.name }}</span>
          <small> ({{ m.attributes.size }})</small>
        </a>
        <button class="d-inline-flex align-center" nbButton status="primary" (click)="deleteMedia.emit(m.id)" size="tiny" ghost>
          <nb-icon icon="trash-2"></nb-icon>
        </button>
      </div>
    </div>
    <div class="col" *ngIf="showDocCtrls">
      <a nbButton status="primary" size="small" ghost class="d-flex justify-content-start" fullWidth [href]="carrierEmailHref">
        <nb-icon icon="email-outline"></nb-icon>
        Contact {{ carrier.attributes.name }} representative
      </a>
      <button
        class="d-flex justify-content-start"
        nbButton
        status="primary"
        size="small"
        ghost
        fullWidth
        *ngIf="quote.attributes.response === false"
        (click)="invite.emit(quote.id)"
      >
        <nb-icon icon="upload-outline"></nb-icon>
        Reinvite {{ carrier.attributes.name }}
      </button>
      <button
        class="d-flex justify-content-start"
        nbButton
        status="primary"
        size="small"
        ghost
        fullWidth
        *ngIf="quote.attributes.response !== false"
        [nbSpinner]="isUploading"
        (click)="fileInput.click()"
      >
        <nb-icon icon="upload-outline"></nb-icon>
        Upload file on behalf of {{ carrier.attributes.name }}
      </button>
      <button
        class="d-flex justify-content-start"
        nbButton
        status="primary"
        size="small"
        ghost
        fullWidth
        *ngIf="quote.attributes.response !== false"
        (click)="decline.emit({ carrier: carrier, quote: quote })"
      >
        <nb-icon icon="slash-outline"></nb-icon>
        Decline on behalf of {{ carrier.attributes.name }}
      </button>
    </div>
    <div class="col" *ngIf="showEnrollCtrls && quote.attributes.response !== false && media.length">
      <button
        class="d-flex justify-content-start"
        nbButton
        status="success"
        ghost
        fullWidth
        (click)="enroll.emit({ quote: quote, options: media, plan: plan })"
      >
        <nb-icon icon="checkmark-square-outline"></nb-icon>
        Enroll
      </button>
    </div>
  </div>
</div>

<input #fileInput type="file" class="d-none" (change)="onFileInputChange($event)" />
