<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <nb-card status="basic">
    <nb-card-header>Plan Administrator Coverage Change Requests</nb-card-header>
    <nb-card-body>
      <div>{{ plan?.attributes?.deviations }}</div>
      <hr />

      <nb-checkbox formControlName="acknowledgement" [disabled]="form.disabled"
        >These changes have been acknowledged and addressed in the plan rates.</nb-checkbox
      >
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">Submit</button>
    </nb-card-footer>
  </nb-card>
</form>
