import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICarrier, IMedia, IPlan, IQuote, IUser, MediaModel, MediaType } from '@app/data';

@Component({
  selector: 'pip-carrier-document-detail',
  templateUrl: './carrier-document-detail.component.html',
  styleUrls: ['./carrier-document-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierDocumentDetailComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public quote: IQuote;
  @Input() public carrier: ICarrier;
  @Input() public media: IMedia[];
  @Input() public isUploading: boolean = false;
  @Input() public isDeleting: boolean = false;
  @Input() public showEnrollCtrls: boolean;
  @Input() public showDocCtrls: boolean;
  @Input() public users: EntityMap<IUser>;

  @Output() public decline = new EventEmitter<{ carrier: ICarrier; quote: IQuote }>();
  @Output() public invite = new EventEmitter<string>();
  @Output() public enroll = new EventEmitter<{ quote: IQuote; options: IMedia[]; plan: IPlan }>();
  @Output() public uploadMedia = new EventEmitter<FormData>();
  @Output() public deleteMedia = new EventEmitter<string>();

  public mediaType = MediaType.Quote;

  public get carrierEmailHref(): string {
    return `mailto:${this.users[this.carrier.relationships.users.data[0].id].attributes.email}`;
  }

  public get selectedMedia(): IMedia {
    return this.media.find(m => m.id === this.quote.attributes.selected_media_id);
  }

  constructor() {}

  public ngOnInit(): void {}

  public onFileInputChange({ target }: { target: HTMLInputElement }) {
    const file: File = target.files[0];

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', MediaType.Quote);
    formData.append('model', MediaModel.Quote);
    formData.append('model_id', this.quote.id);

    this.uploadMedia.emit(formData);
    target.value = null;
  }
}
